<template>
    <div style="margin: 20px 0;">
        <v-skeleton-loader
          type="article, actions"
        ></v-skeleton-loader>    
    </div>

</template>

<script>

export default {
    name: 'HospitalSkeletonLoader',
    data: function() {
        return {};
    },
    methods: {},
};

</script>
<style  scoped>
</style>